<template>
  <v-container style="max-height: 90vh; overflow-y: auto;">
    <v-row no-gutters class="mb-2">

      <!-- Title + Close icon -->
      <v-col cols="12">
        <v-row no-gutters align="center">
          <v-col cols="1">
            <v-btn
              @click="getItinerary"
              icon
              color="primary"
              style="width: 30px !important; margin-top: -7px !important;">
              <v-icon small>fas fa-redo</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <h3 class="font-weight-bold text-center" style="margin:0 auto !important;">{{$t('view.itinerary.prepare')}}</h3>
          </v-col>
          <v-col cols="1">
            <v-btn icon @click="goBack">
              <v-icon size="30" color="primary">fal fa-times</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- Search inputs -->
      <v-col cols="12" class="mt-4">
        <v-card style="border-radius: 10px !important;">
          <v-row no-gutters align="center" >
            <v-col cols="11">
              <v-row no-gutters class="mx-4">

                <!-- From input -->
                <v-col cols="12" class="my-2">
                  <SearchBarItineraryCustom
                    v-if="!isLoadingItineraryFrom"
                    :placeholder="$t('view.itinerary.inputs.departure')"
                    :no-data-text="$t('view.itinerary.inputs.departure-no-data')"
                    :value="itineraryFrom"
                    @selected="onSelectedFrom"
                    @reset="onSelectedFrom(null)"
                    @submit="submitItinerary"
                  />
                  <Skeleton
                    v-else
                    type="list-item"
                    :occurrence="1" />
                </v-col>

                <v-col cols="12">
                  <v-divider />
                </v-col>

                <!-- To input -->
                <v-col cols="12" class="my-2">
                  <SearchBarItineraryCustom
                    v-if="!isLoadingItineraryTo"
                    :placeholder="$t('view.itinerary.inputs.destination')"
                    :no-data-text="$t('view.itinerary.inputs.destination-no-data')"
                    :value="itineraryTo"
                    @selected="onSelectedTo"
                    @reset="onSelectedTo(null)"
                    @submit="submitItinerary"
                  />
                  <Skeleton
                    v-else
                    type="list-item"
                    :occurrence="1" />
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="1" class="text-center">
              <v-btn icon width="25" height="25" class="mr-1" @click="inverse">
                <v-img width="15" src="@/assets/icons/controls/inversion.svg" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card>

      </v-col>

      <!-- From time -->
      <v-col cols="12" class="mt-6">
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto" >
            <v-icon color="primary">far fa-clock</v-icon>
            <v-btn @click="toggleDepartureArrival" class="ml-2" color="white">
              <v-row no-gutters justify="space-between">
                <div>{{isDeparture ? 'Départ' : 'Arrivée'}}</div>
                <div class="ml-2">
                  <v-icon small color="primary">fas fa-exchange-alt</v-icon>
                </div>
              </v-row>
            </v-btn>
          </v-col>

          <!-- InputDatetimePicker -->
          <v-col cols="auto">
            <InputDatetimePicker
              :datetime="date_time"
              @input="setDateTime"
            />
          </v-col>

          <v-col cols="auto">
            <v-btn
              @click="setTimeNow"
              :disabled="!isDeparture"
              :color="isNow ? 'white' : 'black'"
              style="width: 100px !important;font-size: 14px !important;">Maintenant</v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- Modes -->
      <v-col cols="12" >
        <v-row no-gutters justify="space-between" align="center">
          <v-col cols="auto">
            <v-row no-gutters align="center" justify="start">
              <v-icon class="mt-4" color="primary">fas fa-walking</v-icon>
              <span class="mx-2 mt-4" style="font-size: 14px !important;">Préférence des modes</span>
              <v-select
                v-model="transport_preference"
                dense
                hide-details
                class="pt-4 pb-0"
                style="width: 200px !important;border: none !important;"
                @change="onSelectedTransportPreference"
                :items="transport_preferences"
                solo>
                <template v-slot:append>
                  <v-icon color="primary" size="18" class="mt-0">far fa-chevron-down</v-icon>
                </template>
              </v-select>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <!-- Has wheelchair -->
      <v-col cols="12" class="mt-2">
        <v-checkbox
          color="primary"
          dense
          style="font-size: 14px !important;margin-top: 0 !important;"
          :label="$t('view.itinerary.inputs.hasWheelchair')"
          @change="setHasWheelchair"
          v-model="hasWheelchair" />
      </v-col>
      
      <!-- Results -->
      <v-col cols="12">
        <v-row no-gutters v-if="Array.isArray(journeys) && journeys.length > 0 && !isLoadingJourneys">

          <v-col cols="10" offset="1">
            <v-divider />
          </v-col>

          <!-- Modes --
          <v-col cols="12" class="mt-4">
            <v-row no-gutters justify="space-around">
              <v-col cols="auto">
                <v-btn text>
                  <v-icon color="primary" large>far fa-walking</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text>
                  <v-icon color="primary" large>fas fa-bicycle</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text>
                  <v-icon color="primary" large>fas fa-subway</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn text>
                  <v-icon color="primary" large>fas fa-car</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col-->
          <v-col cols="12" class="my-4">
            <v-card style="border-radius: 10px !important;">
              <v-row no-gutters class="my-1">
                <template v-for="(journey) of journeys">
                  <v-col cols="12">
                    <v-card min-height="50" class="ma-3" style="border-radius: 10px !important;">
                      <v-row no-gutters justify="space-between" align="center">
                        <v-col cols="18" class="my-2">
                          <template v-for="(section) of journey.sections">
                            <ItineraryIconMode :section="section" style="margin-left: 2px !important;"/>
                          </template>
                        </v-col>
                        <v-col cols="2" class="text-end">
                          <span class="mx-2">{{formatDuration(journey.durations.total)}}</span>
                        </v-col>
                        <v-col cols="1" style="width: 20px !important;" class="text-end mr-2">
                          <v-btn icon elevation="2" @click="showItinerary(journey)">
                            <img src="@/assets/icons/controls/go_btn.svg" width="20" class="ml-1"/>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </v-card>
          </v-col>

        </v-row>

        <v-divider
          v-if="Array.isArray(journeys) && journeys.length === 0 && !isLoadingJourneys"
          class="my-2"
        />
        <h3 v-if="Array.isArray(journeys) && journeys.length === 0 && !isLoadingJourneys">{{$t('view.itinerary.no-result')}}</h3>

        <Skeleton
          v-if="isLoadingJourneys"
          type="list-item"
          :occurrence="3" />
      </v-col>

      <!-- Tabs addresses -->
      <v-col cols="12" class="mt-3" v-if="$session.exists()">
        <v-divider />
        <v-tabs
          grow
          :show-arrows="false"
          v-model="tabAddress">
          <v-tab style="text-transform: none !important;color: black !important;font-size: 16px !important;" class="font-weight-bold">{{ $t('view.itinerary.tabs.history') }}</v-tab>
          <v-tab style="text-transform: none !important;color: black !important;font-size: 16px !important;" class="font-weight-bold">{{ $t('view.itinerary.tabs.favorites') }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabAddress">

          <!-- Search History -->
          <v-tab-item class="mt-3">
            <v-row no-gutters>
              <v-col cols="12" v-if="!historyLoaded">
                <Skeleton
                  :occurrence="3"
                  type="list-item" />
              </v-col>

              <v-col cols="12" v-else-if="$session.exists() && historyLoaded">
                <p v-if="history.length === 0">{{$t('view.history.no-result')}}</p>
                <v-card class="my-2" style="border-radius: 10px !important;" v-if="history.length > 0">
                  <v-list>
                    <template v-for="(el) of history">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon color="primary" size="20">far fa-search</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title @click="goToCoordinates(el.coordinatesTo)" style="cursor: pointer !important;">{{el.addressTo}}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon elevation="2" class="mr-1" @click="goToItinerary(el.addressTo, el.coordinatesTo)">
                            <img src="@/assets/icons/controls/go_btn.svg" width="20" class="ml-1"/>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>

          <!-- Favorites -->
          <v-tab-item class="mt-3">
            <v-row no-gutters>
              <v-col cols="12" v-if="!favoritesLoaded">
                <Skeleton
                  :occurrence="3"
                  type="list-item" />
              </v-col>

              <v-col cols="12" v-else-if="$session.exists() && favoritesLoaded">
                <p v-if="favorites.length === 0">{{$t('view.favorites.no-result')}}</p>

                <v-card class="my-2" style="border-radius: 10px !important;" v-if="favorites.length > 0">
                  <v-list>
                    <template v-for="(el) of favorites">
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon v-if="el.type === 'HOME'" color="primary" size="20">far fa-home</v-icon>
                          <v-icon v-else-if="el.type === 'WORK'" color="primary" size="20">far fa-briefcase</v-icon>
                          <v-icon v-else color="primary" size="20">far fa-star</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title @click="goToCoordinates(el.coordinates)" style="cursor: pointer !important;">{{el.address}}</v-list-item-title>
                          <v-list-item-subtitle v-if="!['HOME', 'WORK'].includes(el.type)">{{el.label}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon elevation="2" class="mr-1" @click="goToItinerary(el.address, el.coordinates)">
                            <img src="@/assets/icons/controls/go_btn.svg" width="20" class="ml-1"/>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>

    </v-row>

    <AdsSquare />
    <Footer />
  </v-container>
</template>

<script>
import {DateTime} from "luxon";

export default {

  name: "Itinerary",

  components: {
    Footer: () => import("@/components/Common/Footer"),
    AdsSquare: () => import("@/components/Common/AdsSquare"),
    MyFavoriteCard: () => import("@/components/Account/MyFavoriteCard"),
    ItineraryIconMode: () => import("@/components/Common/ItineraryIconMode"),
    SearchBarItineraryCustom: () => import("@/components/Itinerary/SearchBarItineraryCustom"),
    Skeleton: () => import("@/components/Common/Skeleton"),
    MyHistoryCard: () => import("@/components/Account/MyHistoryCard"),
    ItineraryDetails: () => import("@/views/ItineraryDetails"),
    InputDatetimePicker: () => import("@/components/Common/Inputs/InputDatetimePicker")
  },

  data() {
    return {
      tabAddress: 0,

      history: [],
      historyLoaded: false,

      favorites: [],
      favoritesLoaded: false,

      isLoadingJourneys: false,
      isLoadingItineraryFrom: false,
      isLoadingItineraryTo: false,
      hasWheelchair: false,
      date_time: null,
      isDeparture: true,
      isNow: true,
      transport_preference: null,

      transport_preferences: [
        {value: null, text: "Tous"},
        {value: "walking", text: "Piéton"},
        {value: "bike", text: "Vélo"},
        {value: "bss", text: "Station de vélo"},
        {value: "car_no_park", text: "Voiture"},
        {value: "taxi", text: "Taxi"}
      ],

      currentModeJourney: "walking",
      selectedJourneys: []
    }
  },

  async created() {

    if (this.$session.exists()) {
      this.getHistory();
      this.getFavorites();
    }

    console.log(this.journeys);
    this.setTimeNow();
    if (this.$route.query.from) {
      const coordsFrom = this.$route.query.from.split(",");

      this.$store.commit("setCoordinates", {lat: coordsFrom[0], lng: coordsFrom[1]});
      this.$store.commit("setZoom", 15);

      // Is not in the Store ->
      if (!(this.itineraryFrom &&
          coordsFrom.length === 2 &&
          String(this.itineraryFrom.coords.lng) === coordsFrom[1] &&
          String(this.itineraryFrom.coords.lat) === coordsFrom[0])) {

        this.$store.commit("itinerary/resetItineraryFrom");
        this.$store.commit("itinerary/setJourneys", null);

        // Get label from coordinates via Mapbox API
        this.isLoadingItineraryFrom = true;
        if (!!this.$route.query.fromLabel)
          this.itineraryFrom = {label: this.$route.query.fromLabel, coords: {lat: coordsFrom[0], lng: coordsFrom[1], }};
        else {
          const a = await this.getReverseGeocoding(coordsFrom);
          if (a)
            this.itineraryFrom = a;
        }
        this.isLoadingItineraryFrom = false;
      }
    }
    else {
      this.$store.commit("itinerary/setJourneys", null);
      this.$store.commit("itinerary/resetItineraryFrom");
    }

    if (this.$route.query.to) {
      const coordsTo = this.$route.query.to.split(",");

      if (this.itineraryFrom.label === null) {
        this.$store.commit("setCoordinates", {lat: coordsTo[0], lng: coordsTo[1]});
        this.$store.commit("setZoom", 15);
      }

      // Is not in the Store ->
      if (!(this.itineraryTo &&
        Array.isArray(coordsTo) &&
        coordsTo.length === 2 &&
        String(this.itineraryTo.coords.lat) === coordsTo[0] &&
        String(this.itineraryTo.coords.lng) === coordsTo[1])) {

        this.$store.commit("itinerary/resetItineraryTo");
        this.$store.commit("itinerary/setJourneys", null);

        // Get label from coordinates via Mapbox API
        this.isLoadingItineraryTo = true;
        if (!!this.$route.query.toLabel)
          this.itineraryTo = {label: this.$route.query.toLabel, coords: {lat: coordsTo[0], lng: coordsTo[1]}};
        else {
          const a = await this.getReverseGeocoding(coordsTo);
          if (a)
            this.itineraryTo = a;
        }
        this.isLoadingItineraryTo = false;
      }
    }
    else {
      this.$store.commit("itinerary/setJourneys", null);
      this.$store.commit("itinerary/resetItineraryTo");
    }

    this.$store.commit("pageHead/setTitle", this.$t('view.itinerary.pageTitle'))

    if (this.$route.query.mode && this.$route.query.mode !== "null")
      this.transport_preference = this.$route.query.mode;

    if (this.$route.query.hasWheelchair)
      this.hasWheelchair = this.$route.query.hasWheelchair === 'true';

    //if (this.$route.query.)
    if (this.journeys === null || this.journeys?.length === 0)
      this.getItinerary();

  },

  methods: {

    getItinerary() {

      if (this.itineraryFrom.coords.lat &&
          this.itineraryFrom.coords.lng &&
          this.itineraryTo.coords.lat &&
          this.itineraryTo.coords.lng) {
        this.isLoadingJourneys = true;

        let url = `/map/viewer/journeys?coordsFrom=${this.itineraryFrom.coords.lat},${this.itineraryFrom.coords.lng}&coordsTo=${this.itineraryTo.coords.lat},${this.itineraryTo.coords.lng}&hasWheelchair=${this.hasWheelchair}&mode=${this.transport_preference}`;

        if (!this.isNow)
          url += `&isDeparture=${this.isDeparture}&datetime=${this.date_time}`;

        this.$http
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$session.get('jwt')}`
            }
          })
          .then((res) => {
            //console.log(res.data);
            this.journeys = res.data;
          })
          .catch(err => {
            this.$store.commit("toast/showError", this.$t('common.errors.500'));
          })
        .finally(() => {
          this.isLoadingJourneys = false;
        })
      }
    },

    getHistory() {
      this.historyLoaded = false;

      this.$http
        .get(`/map/users/${this.$session.get('id')}/search-history?limit=5&sortBy=meta.created.date&sortDesc=true`, {
          headers: {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          }
        })
        .then((res) => {
          this.history = res.data.items;
        })
        .catch(err => {
          this.$store.commit("alert/showError", this.$t('view.history.errors.NOT_LOADED'));
        })
        .finally(() => {
          this.historyLoaded = true;
        })
    },

    getFavorites() {
      this.favoritesLoaded = false;
      this.$http
        .get(`/map/users/${this.$session.get('id')}/favorites?limit=5`, {
          headers: {
            Authorization: `Bearer ${this.$session.get('jwt')}`
          }
        })
        .then((res) => {
          this.favorites = res.data.items;
        })
        .catch(err => {
          this.$store.commit("alert/showError", this.$t('view.favorites.errors.NOT_LOADED'));
        })
        .finally(() => {
          this.favoritesLoaded = true;
        });
    },

    onSelectedFrom(val) {

      console.log(val);
      this.$store.commit("itinerary/setItineraryFrom", val);
      if (val) {
        this.$router.replace({query: {...this.$route.query, coords: `${val.coords.lat},${val.coords.lng}`, from: `${val.coords.lat},${val.coords.lng}`, fromLabel: val.label, }});
      }

      this.getItinerary();
    },

    onSelectedTo(val) {
      this.$store.commit("itinerary/setItineraryTo", val);
      if (val)
        this.$router.replace({query: {...this.$route.query, to: `${val.coords.lat},${val.coords.lng}`, toLabel: val.label, }});
      this.getItinerary();
    },

    inverse() {

      const a = JSON.parse(JSON.stringify(this.itineraryFrom));
      this.itineraryFrom = JSON.parse(JSON.stringify(this.itineraryTo));
      this.itineraryTo = a;

      this.getItinerary();

      this.$router.replace({query: {
        to: `${this.itineraryTo.coords.lat},${this.itineraryTo.coords.lng}`,
        toLabel: this.itineraryTo.label,
        from: `${this.itineraryFrom.coords.lat},${this.itineraryFrom.coords.lng}`,
        fromLabel: this.itineraryFrom.label
        }
      });
    },

    showItinerary(journey) {
      this.currentItinerary = journey;
      this.$router.push({name: "ItineraryDetails", query: {from: this.$route.query.from, to: this.$route.query.to, coords: this.$route.query.from}});
    },

    formatDuration(duration) {
      const h = Math.floor(duration / 3600);
      const m = Math.floor(duration % 3600 / 60);

      let retStr = "";

      if (h > 0)
        retStr += `${h}h`;
      if (m > 0)
        retStr += `${m}min`;

      return retStr;
    },

    getReverseGeocoding(coords) {

      return new Promise((resolve, reject) => {
        this.$http
          .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coords[1]},${coords[0]}.json?access_token=${process.env.VUE_APP_MAPBOX_TOKEN}&limit=1`)
          .then((res) => {
            if (res.data && res.data.features.length > 0)
              return resolve({label: res.data.features[0].place_name, coords: res.data.query});
            return resolve(null);
          })
          .catch(err =>  {
            return resolve(null);
          })
      })
    },

    goBack() {
      if (window.history.length)
        this.$router.back();
    },

    goToItinerary(label, coordinates) {

      navigator.geolocation.getCurrentPosition((position) => {
        this.$router.push({query: {...this.$route.query, fromLabel: "Ma position", from: `${position.coords.latitude},${position.coords.longitude}`, toLabel: label, to: `${coordinates.lat},${coordinates.lng}`}});
      }, (error) => {
        this.$router.push({query: {...this.$route.query, toLabel: label, to: `${coordinates.lat},${coordinates.lng}`}});
      });
    },

    setTimeNow() {
      this.date_time = this.isNow ? DateTime.now().toString() : null;
      //this.isNow = !this.isNow;
      this.getItinerary();
    },

    setDateTime(val) {
      this.date_time = val;
      this.getItinerary();
    },

    toggleDepartureArrival() {
      this.isDeparture = !this.isDeparture;
      if (!this.isDeparture) {
        this.date_time = this.isNow ? DateTime.now().toString() : null;
        this.isNow = false;
      }
      this.getItinerary();
    },

    onSelectedTransportPreference(val) {
      this.$router.replace({query: {...this.$route.query, mode: val}});
      this.getItinerary();
    },

    setHasWheelchair(val) {
      this.$router.replace({query: {...this.$route.query, hasWheelchair: val}});
      this.getItinerary();
    },

    switchItineraryMode(val) {

    },

    goToCoordinates(coordinates) {
      this.$router.replace({query: {...this.$route.query, coords: `${coordinates.lat},${coordinates.lng}`}});
    },

    submitItinerary() {
      this.$store.commit("setShowAdDialog", true);
      this.getItinerary();
    }
  },

  computed: {
    itineraryFrom: {
      get() {
        return this.$store.getters["itinerary/itineraryFrom"];
      },
      set(val) {
        this.$store.commit("itinerary/setItineraryFrom", val);
      }
    },
    itineraryTo: {
      get() {
        return this.$store.getters["itinerary/itineraryTo"];
      },
      set(val) {
        this.$store.commit("itinerary/setItineraryTo", val);
      }
    },

    currentItinerary: {
      get() {
        return this.$store.getters["itinerary/currentItinerary"];
      },
      set(val) {
        this.$store.commit("itinerary/setCurrentItinerary", val);
      }
    },

    journeys: {
      get() {
        return this.$store.getters['itinerary/journeys'];
      },
      set(val) {
        this.$store.commit("itinerary/setJourneys", val);
      }
    }
  },

  watch: {
    async "$route.query.from"(newValue, oldValue) {
      //console.log(this.itineraryFrom.coords);
      if (newValue !== oldValue && (newValue !== [this.itineraryFrom.coords.lat, this.itineraryFrom.coords.lng].join())) {
        this.isLoadingItineraryFrom = true;
        const coordsFrom = this.$route.query.from.split(",");

        if (!!this.$route.query.fromLabel) {
          this.itineraryFrom = {label: this.$route.query.fromLabel, coords: {lat: coordsFrom[0], lng: coordsFrom[1]}};
        }
        else {
          const a = await this.getReverseGeocoding(this.$route.query.from.split(","));
          if (a)
            this.itineraryFrom = a;
        }
        this.isLoadingItineraryFrom = false;
        this.getItinerary();
      }
    },

    async "$route.query.to"(newValue, oldValue) {
      //console.log(this.itineraryFrom.coords);
      if (newValue !== oldValue && (newValue !== [this.itineraryTo.coords.lat, this.itineraryTo.coords.lng].join())) {
        this.isLoadingItineraryTo = true;
        const coordsTo = this.$route.query.to.split(",");

        if (!!this.$route.query.toLabel)
          this.itineraryTo = {label: this.$route.query.toLabel, coords: {lat: coordsTo[0], lng: coordsTo[1]}};
        else {
          const a = await this.getReverseGeocoding();
          if (a)
            this.itineraryTo = a;
        }
        this.isLoadingItineraryTo = false;
        this.getItinerary();
      }
    }
  }
}
</script>

<style>
.v-tabs-bar.v-tabs-bar--is-mobile .v-slide-group__prev--disabled {
  display: none !important;
}
</style>
